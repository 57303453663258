import { v4 as uuidv4 } from "uuid";
import { analysisCreate, startNFAnalysis } from "../../../actions/projects";
import nf_pipelines from "../analysisWizard/wizardConfig.json";
import axios from "axios";

const uploadFile = async (file, user_bucket, filePath) => {
  console.log("Upload function", filePath, file);
  let inputformData = new FormData();
  inputformData.append("file", file);
  inputformData.append("bucketName", user_bucket);
  inputformData.append("filePath", filePath);

  var config = {
    method: "post",
    // url: "http://localhost:8080/upload",
    url: "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/upload",
    data: inputformData,
  };

  try {
    const response = await axios(config);
    console.log(response);
    console.log("File uploaded done", user_bucket, filePath, file);
  } catch (error) {
    console.log(error);
  }
};

const startAnalysis = async (
  selectedWorkflow,
  inputFileOut,
  referenceFileOut,
  expDesignOut,
  comparisonSelectionOut,
  vmSpeed,
  dispatch,
  currentUser,
  params,
  selectedPipeline
) => {
  let run_id = uuidv4();
  console.log("start analysis");

  // Go to output page
  console.log(
    selectedWorkflow.name,
    inputFileOut,
    referenceFileOut,
    expDesignOut,
    comparisonSelectionOut
  );

  let speeds = {
    slow: {
      cpu: 8,
      mem: 16,
    },
    medium: {
      cpu: 16,
      mem: 32,
    },
    fast: {
      cpu: 32,
      mem: 64,
    },
  };

  // generate input_json by combining data from workflow.json "inputs" & getting bucket_name/file_name from inputFileOut
  let input_json = {};
  selectedWorkflow.inputs.map(async (input) => {
    console.log(input);
    let param_files = [];
    if (input?.name?.includes("read_one_fastqs")) {
      inputFileOut?.map((file) => {
        if (file?.read_num === "1") {
          param_files.push(
            `gs://${file?.bucket_id}/uploads/${file?.file_path}`
          );
        }
      });
      param_files = param_files.sort();
    } else if (input?.name?.includes("read_two_fastqs")) {
      inputFileOut?.map((file) => {
        if (file?.read_num === "2") {
          param_files.push(
            `gs://${file?.bucket_id}/uploads/${file?.file_path}`
          );
        }
      });
      param_files = param_files.sort();
    } else if (input?.name?.includes("gtf")) {
      param_files =
        // "gs://reference-genome-sequences/GRCh38/gencode.v38.basic.annotation.gtf";
        // param_files = "gs://reference-genome-sequences/GRCh38/chr22/gencode_human_chr22.gtf.gz";
        param_files = referenceFileOut.gtf.value
          ? referenceFileOut.gtf.value
          : "";
    } else if (input?.name?.includes("reference_fasta")) {
      // param_files = "gs://reference-genome-sequences/GRCh38/chr22/STARDB.tar.gz";
      // param_files = "gs://reference-genome-sequences/GRCh38/STARDB.tar.gz";
      param_files = referenceFileOut.fasta.value
        ? referenceFileOut.fasta.value
        : "";
    } else if (input?.name?.includes("reference_idx")) {
      // param_files = "gs://reference-genome-sequences/GRCh38/chr22/STARDB.tar.gz";
      // param_files = "gs://reference-genome-sequences/GRCh38/STARDB.tar.gz";
      param_files = referenceFileOut.idx.value;
    } else if (input?.name?.includes("metadata")) {
      // if expDesignOut is a string
      if (!expDesignOut.includes("sample_row\t")) {
        param_files = expDesignOut;
      } else {
        console.log("Uploading metadata file");
        // upload metadata (expDesignOut json) to user bucket
        const user_bucket = `skygenic_user_${currentUser.id}_standard_1`;
        let filePath = `analysis_uploads/${params.id}/${run_id}`;

        console.log(JSON.stringify(expDesignOut));
        const metadata_blob = new Blob([expDesignOut], {
          type: "application/txt",
        });
        const metadata_file = new File([metadata_blob], "metadata.txt", {
          type: "application/txt",
        });

        param_files = `gs://${user_bucket}/${filePath}/metadata.txt`;
        await uploadFile(metadata_file, user_bucket, filePath);
      }
    } else if (input?.name?.includes("comparisons")) {
      param_files = comparisonSelectionOut;
    } else if (input?.name?.includes("count_table")) {
      param_files = `gs://${inputFileOut[0]?.bucket_id}/uploads/${inputFileOut[0]?.file_path}`;
    } else if (input?.name?.includes("map_qc_samples")) {
      param_files = {};
      inputFileOut?.map((file) => {
        param_files[file.file_name] = 1;
      });
    } else if (input.name.includes("ncpu")) {
      console.log(vmSpeed.value, speeds[vmSpeed.value].cpu);
      param_files = speeds[vmSpeed.value].cpu;
    } else if (input.name.includes("memory_gb")) {
      console.log(vmSpeed.value, speeds[vmSpeed.value].mem);
      param_files = speeds[vmSpeed.value].mem;
    }

    input_json[input.name] = param_files;
  });

  console.log("INPUT-JSONN", input_json);

  const nf_workflow_config = nf_pipelines[selectedPipeline]["input_config"];

  let fastq_1 = inputFileOut.filter((file) => file.read_num === "1").sort();
  let fastq_2 = inputFileOut.filter((file) => file.read_num === "2").sort();
  let all_fastq_files = {
    fastq_1: fastq_1.map(
      (file) => `gs://${file.bucket_id}/uploads/${file.file_path}`
    ),
    fastq_2: fastq_2.map(
      (file) => `gs://${file.bucket_id}/uploads/${file.file_path}`
    ),
  };

  console.log("ALL FASTQ FILES", all_fastq_files);

  // Functions
  function parseExpDesignOut(expDesignStr) {
    const lines = expDesignStr.trim().split("\n");
    const headers = lines[0].split("\t");
    const data = lines.slice(1).map((line) => {
      const values = line.split("\t");
      const record = {};
      headers.forEach((header, index) => {
        record[header] = values[index];
      });
      return record;
    });
    console.log("EXP DESIGN", { headers, data });
    return { headers, data };
  }

  function extractSampleId(filePath) {
    const match = filePath.match(/SRR\d+/);
    return match ? match[0] : null;
  }

  function buildFileMapping(all_fastq_files) {
    const fileMapping = {};

    ["fastq_1", "fastq_2"].forEach((fastqKey) => {
      const fileArray = all_fastq_files[fastqKey];
      if (Array.isArray(fileArray)) {
        fileArray.forEach((filePath) => {
          const sampleId = extractSampleId(filePath);
          if (!sampleId) {
            console.warn(`Could not extract sample ID from ${filePath}`);
            return;
          }
          if (!fileMapping[sampleId]) {
            fileMapping[sampleId] = {};
          }
          fileMapping[sampleId][fastqKey] = filePath;
        });
      }
    });

    return fileMapping;
  }

  function generateRows(columns, expData, fileMapping) {
    const rows = [];
    console.log("COLUMNS", columns);
    console.log("EXP DATA", expData);
    console.log("FILE MAPPING", fileMapping);

    // Create a mapping for exp_design headers (case-insensitive)
    const headerMap = Object.keys(expData[0]).reduce((map, header) => {
      map[header.toLowerCase()] = header;
      return map;
    }, {});

    expData.forEach((expRecord) => {
      const sampleId = expRecord[headerMap["sample_row"]];
      const fileData = fileMapping[sampleId];

      if (!fileData) {
        console.warn(`No file data found for sample ${sampleId}`);
        return; // Skip this record if no matching file data
      }

      const row = {};

      columns.forEach((col) => {
        if (col.source === "exp_design") {
          const expField = headerMap[col.field.toLowerCase()];
          row[col.name] = expRecord[expField];
        } else if (col.source === "inputFileOut") {
          row[col.name] = fileData[col.name] || "";
        }
      });

      rows.push(row);
    });

    return rows;
  }

  function convertToCSV(columns, rows) {
    const headers = columns.map((col) => col.name).join(",");
    const csvContent = rows
      .map((row) => {
        return columns.map((col) => `${row[col.name]}`).join(",");
      })
      .join("\n");
    return `${headers}\n${csvContent}`;
  }

  // Processing
  console.log(JSON.stringify(expDesignOut));
  const { data: expData } = parseExpDesignOut(expDesignOut);

  const columns = nf_workflow_config.map((config) => {
    if (config.value && config.value.startsWith("_EXP_DESIGN===")) {
      return {
        name: config.name,
        source: "exp_design",
        field: config.value.split("===")[1],
      };
    } else {
      return {
        name: config.name,
        source: "inputFileOut",
        field: config.name,
      };
    }
  });

  const fileMapping = buildFileMapping(all_fastq_files);
  const csvRows = generateRows(columns, expData, fileMapping);
  console.log(csvRows);
  const input_csv = convertToCSV(columns, csvRows);

  var input_blob = new Blob([input_csv], {
    type: "application/csv",
  });
  var input_file = new File([input_blob], "input.csv", {
    type: "application/csv",
  });

  const user_bucket = `skygenic-user-${currentUser.id}-standard-1`;
  let filePath = `analysis-uploads/${params.id}/${run_id}`;

  let g1g = await uploadFile(input_file, user_bucket, filePath);
  console.log(g1g);

  const nfparams = {
    nextflowPipeline: `../.nextflow/assets/Skygenic/${selectedPipeline}`,
    // release: "main",
    outdir: `gs://${user_bucket}/analysis-runs/${params.id}/${run_id}/outputs`,
    input: `gs://${user_bucket}/analysis-uploads/${params.id}/${run_id}/input.csv`,
    workdir: `gs://${user_bucket}/gcb/workdir/${params.id}/${run_id}`,
    profile: "gcb",
    genome: "GRCh37",
    pseudo_aligner: "salmon",
    run_id: run_id,
  };

  dispatch(
    startNFAnalysis(params.id, {
      // user_id: "6054074f8c41cc4",
      run_id: run_id,
      nfparams: nfparams,
    })
  );

  dispatch(
    analysisCreate(params.id, {
      // user_id: "6054074f8c41cc4",
      run_id: run_id,
      input: JSON.stringify({ input: 1 }),
      status: "Initiating",
      project_id: params.id,
      pipelineDetails: nfparams,
    })
  );
};

export default startAnalysis;