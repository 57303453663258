import React, { useState, useEffect } from "react";
import axios from "axios";
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const Parameters = ({ pipeline_info }) => {
  const [inputsData, setInputsData] = useState(null);
  const [paramsTab, setParamsTab] = useState("outputs");

  const onDownload = (bucketPath) => {
    console.log("download", bucketPath);

    let bucketName = bucketPath.split("/")[2];
    let fileName = bucketPath.split(`${bucketName}/`)[1];
    console.log(bucketName, fileName);
    axios
      .post(
        "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/download",
        {
          bucketName: bucketName,
          fileName: fileName,
        }
      )
      .then((data) => {
        window.open(data.data, "_blank");
      });
  };
  // {
  //   "rnaseq_deseq2_analysis.comparisons": ["Condition_A", "Condition_B", "Condition_C"],
  //   "rnaseq_deseq2_analysis.gtf_annotation": "gs://reference-genome-sequences/GRCh38/gencode.v38.basic.annotation.gtf",
  //   "rnaseq_deseq2_analysis.count_table": "gs://siatik-skygenic-cromwell-runs/rnaseq_star_alignment/a0cd2aa0-6ebc-47c8-ad27-3f83433a2fc1/call-alignment/merged_count_matrix.txt",
  //   "rnaseq_deseq2_analysis.metadata": "gs://siatik_skygenic_service_scripts/pipeline-runner/metadata.tsv"
  // }
  useEffect(() => {
    console.log(pipeline_info);

    // result_filename = result_filename.split("gs://")[1];
    let bucketName = pipeline_info.pipelineDetails?.inputsBucketName;
    let fileName = pipeline_info.pipelineDetails?.inputsBucketFileName;
    console.log(bucketName, fileName);
    axios
      .post(
        "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/download",
        {
          bucketName: bucketName,
          fileName: fileName,
        }
      )
      .then((data) => {
        console.log(data.data);
        axios.get(data.data).then((res) => {
          console.log(res.data);
          setInputsData(res.data);
        });
      });
  }, [pipeline_info]);

  return (
    <div
      className="div-block-26 params"
      style={{
        height: "650px",
      }}
    >
      <div className="div-block-46">
        <div
          className="text-block-20"
          // onClick={startAnalysis}
        >
          Analysis
        </div>
        {/* <div className="text-block-20">Star</div> */}
        <div className="text-block-20">Version 1.0</div>
      </div>
      <div className="div-block-40">
        <div
          className="div-block-41"
          style={{
            backgroundColor: paramsTab === "inputs" ? "#c5c5c5" : "",
          }}
          onClick={() => setParamsTab("inputs")}
        >
          Inputs
        </div>
        <div
          className="div-block-41"
          style={{
            backgroundColor: paramsTab === "settings" ? "#c5c5c5" : "",
          }}
          onClick={() => setParamsTab("settings")}
        >
          Settings
        </div>
        <div
          className="div-block-41"
          style={{
            backgroundColor: paramsTab === "outputs" ? "#c5c5c5" : "",
          }}
          onClick={() => setParamsTab("outputs")}
        >
          Outputs
        </div>
      </div>
      <div
        className="div-block-42"
        style={{
          overflow: "auto",
          maxHeight: "300px",
          display: paramsTab === "inputs" ? "flex" : "none",

          overflowWrap: "anywhere",
        }}
      >
        {/* {pipeline_info.pipelineDetails?.map((pd, i) => {
          return (
            <div
              onClick={() => {
                onDownload(output);
              }}
            >
              {output.split("/").pop()}
            </div>
          );
        })} */}
        {/* {JSON.stringify(inputsData)} */}
      <JsonView data={JSON.parse(pipeline_info?.input)} shouldExpandNode={allExpanded} style={defaultStyles} />

      </div>
      <div
        className="div-block-42"
        style={{
          overflow: "auto",
          maxHeight: "300px",
          display: paramsTab === "settings" ? "flex" : "none",
        }}
      ></div>
      <div
        className="div-block-42"
        style={{
          overflow: "auto",
          maxHeight: "300px",
          display: paramsTab === "outputs" ? "flex" : "none",
          flexDirection: "column"
        }}
      >
      <JsonView data={JSON.parse(pipeline_info.outputs)} shouldExpandNode={allExpanded} style={defaultStyles} />
        
      </div>
    </div>
  );
};

export default Parameters;


// {Object.keys(JSON.parse(pipeline_info.outputs).outputs).map((output, i) => {

//   console.log(output)
//   return (
//     <div
//       onClick={() => {
//         onDownload(output);
//       }}
//     >
//       {/* {output.split("/").pop()} */}
      
//       {output} " : " {JSON.parse(pipeline_info.outputs).outputs[output]}
//       {/* {output.split("/").pop()} */}
//     </div>
//   );
// })}