import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { MaterialReactTable } from "material-react-table";
import {
  listProjectFiles,
  addFilesToProject,
  deleteFileFromProject,
} from "../../actions/projects";
import Nav from "../header/Nav.component";
import Loader from "../loader/Loader.component";
import AddFiles from "../modals/AddFiles.modal";
import PublicDataExplorer from "./publicDataExplorer";
import { CloseButton } from "react-bootstrap";
import { faArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const Files = ({ match }) => {
  const dispatch = useDispatch();
  const projectFiles = useSelector((state) => state.projects.projectFiles);
  const currentUser = useSelector((state) => state.projects.currentUser);

  const [loadingFiles, setLoadingFiles] = useState(true);
  const [showAddFilesModal, setShowAddFilesModal] = useState(false);
  const [showPublicFilesModal, setShowPublicFilesModal] = useState(false);
  const [projectFilesIds, setProjectFilesIds] = useState([]);

  const [toggleFolder, setToggleFolder] = useState(false);
  const [displayFiles, setDisplayFiles] = useState([]);

  const projectId = match.params.id;

  useEffect(() => {
    dispatch(listProjectFiles(match.params.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    console.log(projectFiles, "projectFiles");
    if (!projectFiles) {
      setLoadingFiles(true);
    } else {
      setLoadingFiles(false);
      // if(projectFilesIds.length === 0){
      setProjectFilesIds(projectFiles?.files?.map((file) => file._id));
      // }

      // Create a new array to hold unique first-level folders
      let uniqueFolders = [];
      let indivisualFiles = [];
      projectFiles?.files?.forEach((file) => {
        // Split the file path and get the first folder
        const firstFolder = file.file_path.split("/")[0];
        const ifFolder = file.file_path.split("/").length > 1;

        if (ifFolder && firstFolder && !uniqueFolders.includes(firstFolder)) {
          // Check if the folder is already in the uniqueFolders array
          // If not, add it
          uniqueFolders.push({
            file_name: firstFolder,
            file_path: firstFolder,
            date_created: file.date_created,
            file_type: "Folder",
            file_size: null,
            tags: [],
          });
        } else {
          // If the folder is already in the uniqueFolders array, add the file to the indivisualFiles array
          indivisualFiles.push(file);
        }
      });

      console.log(uniqueFolders, "uniqueFolders");

      // Set displayFiles to the unique folders array
      // setDisplayFiles([...indivisualFiles, ...uniqueFolders]);

      let with_folder_key = [];
      projectFiles?.files?.forEach((file) => {
        const firstFolder = file.file_path.split("/")[0];
        const ifFolder = file.file_path.split("/").length > 1;
        if (ifFolder && firstFolder && !uniqueFolders.includes(firstFolder)) {
          with_folder_key.push({
            ...file,
            folder: firstFolder,
          });
        } else {
          with_folder_key.push({
            ...file,
            folder: null,
          });
        }
      });

      setDisplayFiles(with_folder_key);
    }
  }, [projectFiles]);

  const columns = [
    {
      header: "Name",
      accessorKey: "file_name", // accessorKey is the "key" in the data
      Cell: ({ renderedCellValue, row }) => (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {row.original.file_type === "SRA" ? (
            <strong
              className="file"
              style={{
                width: "100%",
              }}
            >
              <FontAwesomeIcon icon={faArchive} />
              &nbsp;&nbsp;&nbsp;{renderedCellValue}
            </strong>
          ) : (
            renderedCellValue
          )}
        </span>
      ),
      sortable: true,
    },
    {
      header: "Added By",
      accessorKey: "owner_nickname",
    },
    {
      header: "Folder",
      accessorKey: "folder", // id is custom and used for non-accessor values
      // id; "folder",
      Cell: ({ row }) => {
        const folders = row.original.file_path
          .split("/")
          .filter((folder) => folder);
        return folders.length > 1 ? folders[0] : null;
      },
      hide: true,

    },
    {
      header: "Tags",
      accessorKey: "tags",
      Cell: ({ row }) => (
        <span>
          {row.original.tags.map((tag, index) => (
            <span key={index} className="tag">
              {tag}
            </span>
          ))}
        </span>
      ),
    },
    {
      header: "Created",
      accessorKey: "date_created",
      Cell: ({ row }) => (
        <span>
          {row.original.date_created ? moment.unix(row.original.date_created).format("MM/DD/YYYY") : ""}
        </span>
      ),
      sortable: true,
    },
    {
      header: "Type",
      accessorKey: "file_type",
    },
    {
      header: "Size",
      accessorKey: "file_size",
      Cell: ({ row }) => (
        <span>
          {row.original.file_size
            ? `${(row.original.file_size / 1000000).toFixed(2)} MB`
            : ""}
        </span>
      ),
    },
    {
      header: "Delete",
      id: "delete",
      Cell: ({ row }) => (
        <CloseButton
          style={{
            width: "50px",
            borderRadius: "3px",
            paddingTop: "0px",
          }}
          onClick={() => {
            if(row.original.file_type === "SRA"){
              console.log(row.original)
              deleteFile({publicDataStudyId: row.original.file_name})
            } else if (row.original.owner_user_id !== currentUser.id)  {
              alert("You are not authorized to delete this file")
            } else {
              deleteFile({fileId: row.original._id})
            }
          }}
        />
      ),
    },
  ];

  const deleteFile = async (id) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      console.log(id);
      dispatch(deleteFileFromProject(projectId, id));
    setTimeout(() => {
      dispatch(listProjectFiles(match.params.id));
    }, 2000);

    }
  };

  const handleFilesModalClose = () => {
    setShowAddFilesModal(false);
    dispatch(listProjectFiles(match.params.id));

  };

  const handlePublicFilesModalClose = () => {
    setShowPublicFilesModal(false);
    setTimeout(() => {
      dispatch(listProjectFiles(match.params.id));
    }, 3000);

  }

  return (
    <>
      <Nav active={2} />
      {false ? (
        <div className="loader flex-center">
          <Loader />
        </div>
      ) : (
        <>
          <div style={{ margin: "2em auto", width: "96%" }}>
            <div className="boxed_section">
              <div className="boxed_header">
                <h3>Project Files</h3>
                <Link
                  to="#"
                  className="button"
                  onClick={() => setShowPublicFilesModal(true)}
                >
                  Public Access Files
                </Link>
                &nbsp;&nbsp;
                <Link
                  to="#"
                  className="button"
                  onClick={() => {setShowAddFilesModal(true)                  
                  }}
                >
                  Add Your Storage Files
                </Link>
                {showAddFilesModal ? (
                  <AddFiles
                    projectId={projectId}
                    selectedFileIds={projectFilesIds}
                    showModal={showAddFilesModal}
                    onHide={handleFilesModalClose}
                  />
                ) : null}
                {showPublicFilesModal ? (
                  <PublicDataExplorer
                    projectId={projectId}
                    showPublicFilesModal
                    onHide={handlePublicFilesModalClose}
                    
                  />
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <></>
                
              </div>
              <div className="boxed_content">
                <MaterialReactTable
                  columns={columns}
                  data={displayFiles || []}
                  initialState={{
                    density: "compact",
                    expanded: false,
                    grouping: toggleFolder ? ["folder"] : [],
                    pagination: { pageIndex: 0, pageSize: 15 },
                    sorting: [{ id: "date_created", desc: false }],
                  }}
                  state={{
                    isLoading: loadingFiles,
                    grouping: toggleFolder ? ["folder"] : [],
                  }}
                  enableSorting
                  enablePagination
                  enableGrouping
                  renderTopToolbarCustomActions={({ table }) => (
                      <FormGroup style={{
                        marginLeft: "10px"
                      }}>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked
                        checked={toggleFolder}
                        onChange={() => setToggleFolder(!toggleFolder)}
                      />
                    }
                    label="Toggle Folders"
                  />
                </FormGroup>
                  )}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Files;
