import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import ExpDesignModal from "../ExpDesignModal";

const ComparisonSelection = ({ expDesign, setComparisonSelectionOut }) => {
  const [selectedExpDesign, setSelectedExpDesign] = useState([]);



  useEffect(() => {
    console.log(selectedExpDesign, selectedExpDesign.length, expDesign)
    if(selectedExpDesign.length === 0){
      setComparisonSelectionOut(expDesign)
      setSelectedExpDesign(expDesign)
    } else{
      setComparisonSelectionOut(selectedExpDesign)
    }
  }, [selectedExpDesign, expDesign])

  return (
    <>
      <div className="text-block-50">
        For the differential expression analysis the identified conditions of
        the experimental design must be selected that should be compared against
        each other. A minimum of two conditions must be selected.
      </div>
      <div className="div-block-66">
        {expDesign.map((xd) => {
          return (
            <div
              className="text-block-42"
              style={
                selectedExpDesign.includes(xd)
                  ? {
                      backgroundColor: "rgba(46, 63, 255, 0.74)",
                      color: "#fff",
                    }
                  : {}
              }
              onClick={() => {
                console.log(xd in selectedExpDesign);
                console.log(selectedExpDesign);

                if (selectedExpDesign.includes(xd)) {
                  setSelectedExpDesign((sxd) =>
                    sxd.filter((item) => item !== xd)
                  );
                } else {
                  setSelectedExpDesign((sxd) => [...sxd, xd]);
                }
              }}
            >
              {xd}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ComparisonSelection;
