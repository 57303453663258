import React, { useState, useEffect, useRef, useMemo } from "react";
import Select from "react-select";
import Plotly from "plotly.js/dist/plotly";
import Plot from "react-plotly.js";
import { cloneDeep, inRange } from "lodash";
import axios from "axios";
import * as SvgToPng from "save-svg-as-png";
import { useSelector, useDispatch } from "react-redux";
import { analysisAddReport } from "../../../../actions/projects";
import HeatmapTable from "./heatmapTable";
import ScatterTable from "./scatterTable";
import { ToastContainer, toast } from 'react-toastify';

const Visual = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.projects.currentUser);
  const [heatMapData, setHeatMapData] = useState(null);
  const [graphDiv, setGraphDiv] = useState(null);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [viewSwitch, setViewSwitch] = useState("both");
  const [filteredItems, setFilteredItems] = useState(null);
  const [selectGene, setSelectGene] = useState(null);
  const [chartType, setChartType] = useState("scatter");
  const [transformedData, setTransformedData] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    if (data.length === 0) {

      // if (!props?.pipeline_info?.outputs[0]?.includes("deseq2")) {
      //   return;
      // }

      try {
        let result_filename = JSON.parse(props.pipeline_info.outputs).outputs['rnaseq_full_workflow.full_results']
        result_filename = result_filename[0][0]
        // result_filename = result_filename.split("gs://")[1];
        let bucketName = result_filename.split("/")[2];
        let fileName = result_filename.split(bucketName + "/")[1];

        console.log(bucketName, fileName);

        axios
          .post(
            "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/storagefile",
            {
              bucketName: bucketName,
              fileName: fileName,
            }
          )
          .then((data) => {
            // Do something with your data

            console.log(data);
            const result = data.data.split("\n").map((s) => s.split(","));
            result.forEach((row, i) => {
              if (i === 0) {
                return;
              }
              row[2] = Number(row[2]);
              row[3] = row[3] === "NA" ? null : Number(row[3]);
              row[4] = row[4] === "NA" ? null : Number(row[4]);
            });

            const cols = result[0].map((x, i) => {
              return {
                name: x,
                selector: (row) => row[i],
                sortable: true,
              };
            });

            setColumns(cols);
            setData(result.slice(1));
            setAllData(result.slice(1).splice(1, 5000));
            setFilteredItems(result.slice(1).filter((x, i) => i < 5000));
            // console.log(result.slice(1));
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const onchangeSelect = (items) => {
    console.log(items);

    setSelectGene(items);
    if (items.length === 0) {
      setFilteredItems(allData.slice(0, 5000));
      return;
    }

    let fi = cloneDeep(allData);

    fi = fi.filter((arr) => items.some((obj) => obj.value === arr[1]));

    console.log(fi);
    setFilteredItems(fi);
  };

  const onScatterSelect = (e) => {
    let x_range = e.range.x;
    let y_range = e.range.y;

    let fi = cloneDeep(filteredItems);
    let gg = fi.filter((item, i) => {
      return (
        inRange(item[3], x_range[0], x_range[1]) &&
        inRange(item[4], y_range[0], y_range[1])
      );
    });
    console.log(gg);
    setFilteredItems(gg);
  };

  // ----- 3rd party integration -----

  const sendToEnrichr = () => {
    console.log("Send data to Enrichr");
    let f_data = filteredItems.map((x) => {
      return x[1];
    });
    f_data = f_data.join("\n");

    var e_data = new FormData();
    e_data.append("list", f_data);
    e_data.append("description", "Skygenic analysis data");
    axios({
      method: "post",
      url: "https://maayanlab.cloud/Enrichr/addList",
      data: e_data,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(function (res) {
      console.log(res);
      openInNewTab(
        `https://maayanlab.cloud/Enrichr/enrich?dataset=${res.data.shortId}`
      );
    });
  };

  const sendToStringdb = () => {
    console.log("Send data to Stringdb");
    let f_data = filteredItems.map((x) => {
      return x[1];
    });

    if (f_data.length > 700) {
      toast.info("Too many genes selected. Please select less than 700 genes.");
      return;
    }

    f_data = f_data.join("%0d");

    axios
      .get(
        `https://string-db.org/api/json/get_link?identifiers=${f_data}&species=9606`
      )
      .then(function (res) {
        console.log(res);
        openInNewTab(res.data[0]);
      });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  // -------------

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const downloadPNG = () => {
    let g = document.getElementsByClassName("main-svg");
    console.log(g);
    SvgToPng.saveSvgAsPng(g[0], "diagram.png", { scale: 1 });
    // SvgToPng.saveSvgAsPng(g[1], "diagram.png", {scale: 1});
    // SvgToPng.saveSvgAsPng(g[2], "diagram.png", {scale: 1});
  };



  const addToReport = async () => {
    console.log(props.pipeline_info);
    // "rnaseq_deseq2_analysis.full_results"]
    let f_path = props.pipeline_info.outputs[0];
    console.log(f_path);
    let path_list = f_path.split("/");
    let report_path = path_list.slice(3, 5).join("/") + "/reports";
    let bucket_name = path_list[2];

    console.log(report_path);
    console.log(bucket_name);

    let imgData = null;
    if (graphDiv !== null) {
      imgData = await Plotly.toImage(graphDiv, { format: "jpeg" }).then(
        function (dataUrl) {

          return dataUrl;
        }
      );
    }

    let report = {
      graph: JSON.stringify({ imageData: imgData }),
      time: Date.now(),
      details: "Enter report details here",
      user: currentUser?.name,
    }

    dispatch(analysisAddReport(props.pipeline_info.run_id, report));
    toast.success("Added Report");

  };

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  useEffect(() => {
    var _transformedData = filteredItems?.map((row, index) => ({
      column1: row[0],
      column2: row[1],
      column3: row[2],
      column4: row[3],
      column5: row[4],
      // Add more columns if needed
    }));

    setTransformedData(_transformedData);
  }, [filteredItems]);

  // Heatmap csv
  useEffect(() => {
    try{
    if (data.length === 0) {
      console.log(props.pipeline_info.outputs);

      // if (!props?.pipeline_info?.outputs[0]?.includes("deseq2")) {
      //   return;
      // }

      console.log(Object.keys(JSON.parse(props.pipeline_info.outputs)));

      let result_filename =  JSON.parse(props.pipeline_info.outputs).outputs["rnaseq_full_workflow.report_heatmaps"][0]

      console.log(result_filename);
      // result_filename = result_filename.split("gs://")[1];
      let bucketName = 'analysis-run-test'
      let fileName = result_filename?.split(bucketName + "/")[1];

      console.log(bucketName, fileName);

      axios
        .post(
          "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/storagefile",
          {
            bucketName: bucketName,
            fileName: fileName,
          }
        )
        .then((data) => {
          // Do something with your data
          const result = data.data.split("\n").map((s) => s.split(","));
          setHeatMapData(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }

  } catch(err){
    console.log(err)
  }
  }, []);

  const handleFilterChange = (x) => {
    // console.log(x)
    // if(x?.id !== "column4"){
    //   return
    // }

    let new_filteredData = tableRef.current
      .getFilteredRowModel()
      .rows.map((row) => row.original);

    // if(new_filteredData?.length !== transformedData.length){
    console.log(new_filteredData);
    // setFilteredItems(new_filteredData);

    //   if (filteredItems?.length === new_filteredData?.length) {
    //     console.log("same length");
    //     return
    // }

    var newFilteredItems = new_filteredData?.map((row) => [
      row.column1,
      row.column2,
      row.column3,
      row.column4,
      row.column5,
      // Add more columns if needed
    ]);

    console.log("------------", newFilteredItems.length, filteredItems.length);

    if (filteredItems.length !== newFilteredItems.length) {
      console.log("newFilteredItems", newFilteredItems);
      setFilteredItems(newFilteredItems);
    }
  };

  return (
    <div>

      <div className="div-block-53">

        <Select
          value={selectGene}
          onChange={onchangeSelect}
          options={allData
            ?.slice(0, 100)
            .map((gene) => ({ value: gene[1], label: gene[1] }))}
          getOptionValue={(option) => option.value}
          isMulti
          getOptionLabel={(option) => (
            <div
              style={
                {
                  // fontSize: 21,
                }
              }
            >
              {option.label}
            </div>
          )}
          closeMenuOnSelect={false}
          className={"div-block-59"}
          // className={"text-block-21"}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: "none",
              boxShadow: "0 0 4px rgba(118, 126, 255, .57)",
              alignItems: "flex-start",
              // borderColor: state.isFocused ? 'grey' : 'red',
              // height: "42px",
              width: "100%",
            }),
            width: "100%",
          }}
          classNamePrefix={'dropdown-item"'}
          placeholder="Search Gene"
        />
        {/* </div> */}
        <div className="div-data-graph-switch">
          <div
            className={
              viewSwitch === "datatable"
                ? "switch-btn switch-btn-hover"
                : "switch-btn"
            }
            onClick={() => setViewSwitch("datatable")}
          >
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/64a7bdd5414469af1b924379_icons8-list-64.png"
              loading="lazy"
              width={18}
              alt=""
            />
            <div className="text-block-54">DataTable</div>
          </div>
          <div
            className={
              viewSwitch === "both"
                ? "switch-btn switch-btn-hover"
                : "switch-btn"
            }
            onClick={() => setViewSwitch("both")}
          >
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/64a7be6c414469af1b93295b_icons8-split-64.png"
              loading="lazy"
              width={18}
              alt=""
            />
            <div className="text-block-54">Both</div>
          </div>
          <div
            className={
              viewSwitch === "graph"
                ? "switch-btn switch-btn-hover"
                : "switch-btn"
            }
            onClick={() => setViewSwitch("graph")}
          >
            <img
              src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/64a7bb86fbb9f0a55737f544_icons8-graph-64.png"
              loading="lazy"
              width={25}
              alt=""
            />
            <div className="text-block-54">Graph</div>
          </div>
        </div>

        <div
          className="div-block-58"
          style={{
            justifyContent: "space-evenly",
          }}
        >
          <div className="div-block-55 ss" onClick={() => addToReport()}>
            {/* <div className="div-block-55" onClick={() => addToReport2()}> */}
            Add To Report
          </div>
          <div
            className="div-block-55 ss"
            onClick={() => downloadCSV(filteredItems)}
          >
            Download Data
          </div>
          <div className="div-block-55 ss" onClick={sendToStringdb}>
            Send To StringDb
          </div>
          <div className="div-block-55 ss" onClick={sendToEnrichr}>
            Send To Enrichr
          </div>
        </div>
      </div>

      {filteredItems && (
        <div className="div-block-29" style={{ width: "100%" }}>
          <div
            className="div-block-52"
            style={{
              padding: "30px",
              width: viewSwitch === "both" ? "50%" : "100%",
              display: viewSwitch === "graph" ? "none" : "list-item",
              paddingTop: "15px",
              paddingRight: "15px",
            }}
          >
            {chartType === "heatmap" && heatMapData !== null ? (
              <HeatmapTable rawData={heatMapData} />
            ) : (
              // <MaterialReactTable table={table} />
              <ScatterTable
                transformedData={transformedData}
                setFilteredItems={setFilteredItems}
                allData={allData}
              />
            )}
          </div>
          <div
            className="div-block-52"
            style={{
              width: viewSwitch === "both" ? "50%" : "100%",
              display: viewSwitch === "datatable" ? "none" : "flex",
            }}
          >
            {chartType === "heatmap" && heatMapData !== null ? (
              <>
                <Plot
                  data={[
                    {
                      x: heatMapData[0],
                      z: heatMapData.slice(1),
                      type: "heatmap",
                      colorscale: [
                        [0, "#05a1f5"],
                        [0.5, "white"],
                        [1, "#ff673d"],
                      ], // custom colorscale
                      showscale: true,
                      colorbar: {
                        title: "Z-Score for normalized expression", // legend title
                        titleside: "right",
                      },
                    },
                  ]}
                  layout={{
                    title: "Heatmap Chart",
                    margin: { l: 150, r: 150, b: 150, t: 150, pad: 30 },

                    // add margin
                  }}
                />
              </>
            ) : chartType === "scatter" ? (
              <>
                <Plot
                  id="gg-scatter"
                  // ref={graphDiv}
                  onInitialized={(figure, graphDiv) => setGraphDiv(graphDiv)}
                  onPurge={(figure, graphDiv) => setGraphDiv(null)}
                  data={[
                    {
                      x: filteredItems.map((x) => {
                        return x[3];
                      }),
                      y: filteredItems.map((x) => {
                        return x[4];
                      }),
                      text: filteredItems.map((x) => {
                        return `${x[1]}, ${x[2]}`;
                      }),
                      type: "scatter",
                      mode: "markers",
                      // mode: "lines+markers",
                      marker: { color: "rgba(249, 120, 0, 0.5)", width: 0.1 },
                    },
                    // { type: "bar", x: [1, 2, 3], y: [2, 5, 3] },
                  ]}
                  layout={{
                    width: 900,
                    height: 640,
                    title: "Scatter Plot",
                    xaxis: {
                      title: {
                        text: "Log2FC",
                        font: {
                          size: 18,
                          color: "#7f7f7f",
                        },
                      },
                    },
                    yaxis: {
                      title: {
                        text: "Padj",
                        font: {
                          size: 15,
                          color: "#7f7f7f",
                        },
                      },
                    },
                    dragmode: "select",
                  }}
                  onSelected={onScatterSelect}
                  onPan={onScatterSelect}
                  onRelayout={(x) => {
                    console.log(x);
                    if (x?.["xaxis.autorange"]) {
                      setFilteredItems(allData.slice(0, 5000));
                    }
                  }}
                  config={{
                    displayModeBar: true, // this is the default; set to false to hide the mode bar
                    modeBarButtonsToRemove: ["lasso2d", "zoom"],
                  }}
                />
              </>
            ) : (
              <></>
            )}
            <div className="div-block-63">
              {/* <div className="div-block-55" onClick={() => setChartType("bar")}>
                Bar Plot
              </div> */}
              <div
                className="div-block-55 graph"
                onClick={() => setChartType("scatter")}
              >
                Scatter Plot
              </div>
              <div
                className="div-block-55 graph"
                onClick={() => setChartType("heatmap")}
              >
                Heatmap
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Visual);
