import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";
import wizardConfig from "./analysisWizard/wizardConfig.json";



// Function to filter variables based on type, excluding "sample"
const getVariablesFromConfig = (type) => {
  const config = wizardConfig[type];

    if (!config) return [];
    return config.input_config
        .filter(item => item.value && item.value !== "_EXP_DESIGN===sample_row") // Only include items with a value
        .map(item => ({
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1), // Capitalize first letter
            columns: { UNTAGGED: { name: "UNTAGGED", items: [] } }
        }));
};

function ExpDesignModal({
    inputFileOut,
    open,
    setOpenExpDesignModal,
    setExpGenData,
    selectedPipeline
}) {
    const [variables, setVariables] = useState(getVariablesFromConfig(selectedPipeline));
    const [sampleInputFiles, setSampleInputFiles] = useState([]);
    const [selectedVariable, setSelectedVariable] = useState(0);
    const [multiDrag, setMultiDrag] = useState(false);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [hoverColumn, setHoverColumn] = useState(null);

    const hasSampleFilesAdded = useRef(false); // Ref to track if sample files have been added

    useEffect(() => {
        if (inputFileOut) {
            const sampleList = inputFileOut
                .filter(file => file.paired && file.read_num !== "2")
                .map(file => ({ file_name: file.paired, _id: file._id }));
            setSampleInputFiles(sampleList);
        }
    }, [inputFileOut]);

    useEffect(() => {
        const updatedVariables = getVariablesFromConfig(selectedPipeline);
        setVariables(updatedVariables);
        hasSampleFilesAdded.current = false; // Reset when type changes
    }, [selectedPipeline]);

    useEffect(() => {
        if (!sampleInputFiles.length || hasSampleFilesAdded.current) return;

        const updatedVariables = cloneDeep(variables);
        updatedVariables.forEach(variable => {
            variable.columns.UNTAGGED.items = [...sampleInputFiles];
        });
        setVariables(updatedVariables);
        hasSampleFilesAdded.current = true; // Set flag to true after adding files
    }, [sampleInputFiles]);

    const addDimension = () => {
        const updatedVariables = cloneDeep(variables);
        const selectedVariableData = updatedVariables[selectedVariable];
        const newDimensionId = `New Dimension ${Object.keys(selectedVariableData.columns).length + 1}`;

        selectedVariableData.columns[newDimensionId] = {
            name: newDimensionId,
            items: [],
        };
        setVariables(updatedVariables);
    };

    const deleteDimension = (dimensionId) => {
        if (dimensionId === "UNTAGGED" || isDefaultDimension(dimensionId)) return; // Prevent deletion of UNTAGGED or default dimensions

        const updatedVariables = cloneDeep(variables);
        const selectedVariableData = updatedVariables[selectedVariable];
        
        // Move items from the dimension being deleted to the "UNTAGGED" column
        const itemsToMove = selectedVariableData.columns[dimensionId]?.items || [];
        if (itemsToMove.length > 0) {
            selectedVariableData.columns.UNTAGGED.items = [
                ...selectedVariableData.columns.UNTAGGED.items,
                ...itemsToMove
            ];
        }
        
        // Delete the dimension
        delete selectedVariableData.columns[dimensionId];
        
        setVariables(updatedVariables);
    };

    const addNewVariable = () => {
        const newVariable = {
            name: `New Variable ${variables.length + 1}`,
            columns: { UNTAGGED: { name: "UNTAGGED", items: [] } }
        };
        const updatedVariables = [...variables, newVariable];

        // Add sample files to the new variable's UNTAGGED column
        const sampleFilesForNewVariable = [...sampleInputFiles];
        updatedVariables[variables.length].columns.UNTAGGED.items = sampleFilesForNewVariable;

        setVariables(updatedVariables);
        setSelectedVariable(variables.length); // Set to the new variable
    };

    const deleteVariable = (index) => {
        // Prevent deletion of default variables; index 0 is for the first variable
        if (index < 0 || index >= variables.length || isDefaultVariable(index)) return; 

        const updatedVariables = cloneDeep(variables);
        updatedVariables.splice(index, 1);
        setVariables(updatedVariables);
        setSelectedVariable(index > 0 ? index - 1 : 0);
    };

    const isDefaultVariable = (index) => {
        const defaultVariables = getVariablesFromConfig(selectedPipeline)
            .map(v => v.name.toLowerCase());
        return index < defaultVariables.length;
    };

    const isDefaultDimension = (dimensionId) => {
        const defaultDimensions = ["lane", "patient"];
        return defaultDimensions.includes(dimensionId.toLowerCase());
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        const updatedVariables = cloneDeep(variables);
        const selectedVariableData = updatedVariables[selectedVariable];
        
        // Handle dragging of items
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = selectedVariableData.columns[source.droppableId];
            const destColumn = selectedVariableData.columns[destination.droppableId];
            const [removed] = sourceColumn.items.splice(source.index, 1);
            destColumn.items.splice(destination.index, 0, removed);
        }

        // Handle dragging of the whole dimension (when multiDrag is active)
        if (multiDrag && result.type === 'DIMENSION') {
            const sourceColumn = selectedVariableData.columns[source.droppableId];
            const destColumn = selectedVariableData.columns[destination.droppableId];
            const draggedItems = [...sourceColumn.items];
            
            destColumn.items.push(...draggedItems);
            sourceColumn.items = [];
        }

        setVariables(updatedVariables);
    };

    const handleItemClick = (itemId) => {
        if (multiDrag) {
            const updatedSelectedItems = new Set(selectedItems);
            if (updatedSelectedItems.has(itemId)) {
                updatedSelectedItems.delete(itemId);
            } else {
                updatedSelectedItems.add(itemId);
            }
            setSelectedItems(updatedSelectedItems);
        }
    };

    const handleCloseExpModal = () => {
        setOpenExpDesignModal(false);

        const allVariables = variables.map(v => v.name.split(".fastq")[0]);
        const eachFileData = sampleInputFiles.map(file => {
            const fileData = variables.flatMap(variable =>
                Object.values(variable.columns)
                    .filter(col => col.name !== "UNTAGGED")
                    .flatMap(col => col.items.filter(item => item.file_name === file.file_name).map(() => col.name))
            );
            return {
                fileName: file.file_name,
                fileData
            };
        });

        let tsvData = "sample_row\t" + allVariables.join("\t") + "\n";
        eachFileData.forEach(file => {
            tsvData += file.fileName + "\t" + file.fileData.join("\t") + "\n";
        });
        setExpGenData(tsvData);
    };

  return (
    <Modal
      show={open}
      onHide={handleCloseExpModal}
      centered
      dialogClassName="div-block-12"
    >
      <div className="div-block-68" style={{ width: "100vw", height: "100vh" }}>   <div className="div-block-25" onClick={handleCloseExpModal}>
            <img src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/60fc3a2f21621b84f7932381_icons8-back-96.png" loading="lazy" width={16} alt="" />
            <div className="text-block-3">Back</div>
          </div>
        <div className="div-block-13">
       
          <div className="text-block-2 " >Experimental Design</div>
        </div>
        <div className="div-block-70" style={{ height: "80%" }}>
          <div className="div-block-71">
            {variables.map((x, i) => (
              <div
                className="div-block-72"
                key={i}
                style={{ backgroundColor: selectedVariable === i ? "#d0d2db" : "" }}
                onClick={() => setSelectedVariable(i)}
              >
                <div
                  className="text-block-44"
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={(e) => {
                    const updatedVariables = cloneDeep(variables);
                    updatedVariables[i].name = e.target.innerText;
                    setVariables(updatedVariables);
                  }}
                >
                  {x.name}
                </div>
                <img
                  src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/61a4d819dc4a9c68b90806e6_icons8-close-cross-symbol-for-discontinued-and-invalid-48.png"
                  loading="lazy" width={13} alt=""
                  className="image-5"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteVariable(i);
                  }}
                />
              </div>
            ))}
            <div className="div-block-74" onClick={addNewVariable}>
              <img src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/616fe7636a6de343e228cef7_icons8-add-64.png" loading="lazy" width={22} alt="" />
            </div>
          </div>
          <div className="exp-menu-bar">
            <input
              className="exp-filter"
              placeholder="Filter"
              onChange={(e) => {
                const filteredItems = sampleInputFiles.filter(item =>
                  item.file_name.includes(e.target.value)
                );
                const updatedVariables = cloneDeep(variables);
                updatedVariables[selectedVariable].columns.UNTAGGED.items = filteredItems;
                setVariables(updatedVariables);
              }}
            />
            <button
              className="exp-multi-drag-btn"
              style={{
                width: "120px",
                height: "30px",
                marginBottom: "4px",
                color: multiDrag ? "#3b3b53" : "",
                border: multiDrag ? "1px solid #8088e0" : "",
                backgroundColor: multiDrag ? "#dae3ff" : "#ffffff"
              }}
              onClick={() => setMultiDrag(!multiDrag)}
            >
              Multi Drag - {multiDrag ? "ON" : "OFF"}
            </button>
          </div>
          <div className="div-block-73">
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              {variables[selectedVariable]?.columns && 
                Object.entries(variables[selectedVariable].columns).map(([columnId, column]) => (
                  <div
                    className="div-block-75"
                    key={columnId}
                    onMouseEnter={() => setHoverColumn(columnId)}
                    onMouseLeave={() => setHoverColumn(null)}
                  >
                    <div className="div-block-76">
                      <div
                        className="text-block-43"
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => {
                          const updatedVariables = cloneDeep(variables);
                          updatedVariables[selectedVariable].columns[columnId].name = e.target.innerText;
                          setVariables(updatedVariables);
                        }}
                      >
                        {column.name}
                      </div>
                      {columnId === hoverColumn && columnId !== "UNTAGGED" && (
                        <img
                          src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/61a4d819dc4a9c68b90806e6_icons8-close-cross-symbol-for-discontinued-and-invalid-48.png"
                          loading="lazy" width={13} alt=""
                          className="image-5"
                          onClick={() => deleteDimension(columnId)}
                        />
                      )}
                    </div>
                    <Droppable
                        droppableId={columnId}
                        key={columnId} type={multiDrag ? 'DIMENSION' : 'ITEM'}
                        // style={{
                        //   width: "100%"
                        // }}
                      >
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                width: "100%",
                                height: "100%",
                                background: snapshot.isDraggingOver
                                  ? "lightblue"
                                  : "#00000000",
                                padding: "10px",
                                overflow: "hidden",
                              }}
                            >
                              {column?.items?.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item._id}
                                    draggableId={item._id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            padding: 16,
                                            margin: "0 0 8px 0",
                                            minHeight: "50px",
                                            backgroundColor: snapshot.isDragging
                                              ? "#7cb5c054"
                                              : "#fff",
                                            color: "black",
                                            ...provided.draggableProps.style,
                                          }}
                                          className={"div-block-77"}
                                          onClick={() => handleItemClick(item._id)}
                                        >
                                          <div className="text-block-44">
                                          {item.file_name}
                                          </div>
                                          {/* {item.content} */}
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                  </div>
                ))
              }
            </DragDropContext>
            <div className="div-block-74" onClick={addDimension}>
              <img
                src="https://uploads-ssl.webflow.com/60faa77e21b22054e04713b8/616fe7636a6de343e228cef7_icons8-add-64.png"
                loading="lazy"
                width={22}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ExpDesignModal;
