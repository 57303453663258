import React, { useState, useEffect } from "react";
import axios from "axios";

const AutoGenReport = ({ pipeline_info }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    console.log(pipeline_info);
    try {
      let result_filename = JSON.parse(pipeline_info.outputs).outputs["rnaseq_full_workflow.report_static"]
      let bucketName = result_filename.split("/")[2];
      let fileName = result_filename.split(bucketName + "/")[1];

      console.log(bucketName, fileName);

      axios
        .post(
          // "http://localhost:8080/download",
          "https://sg-storage-uploader-dot-data-science-siatik.ew.r.appspot.com/download",
          {
            bucketName: bucketName,
            fileName: fileName,
          }
        )
        .then((data) => {
          console.log(data.data);
          setPdfUrl(data.data);
        });
    } catch (err) {
      console.error(err);
    }
  }, [pipeline_info]);



  return (
    <div
      className="div-block-26"
      style={{
        height: "1000px",
      }}
    >
      <object data={pdfUrl} type="application/pdf" width="100%" height="100%">
        <p>
          Link <a href={pdfUrl}>to the PDF!</a>
        </p>
      </object>
    </div>
  );
};

export default AutoGenReport;
