import React, { useState, useEffect } from "react";
import Select from "react-select";
import ReferenceFile from "./ReferenceFile";

const Summary = ({
  workflow,
  comparisonSelectionOut,
  referenceFileOut,
  inputFileOut,
  vmSpeed,
  setVmSpeed
}) => {
  const [sampleInputFiles, setSampleInputFiles] = useState([]);


  const vmSpeedOptions = [
    {
      value: "high",
      label: "High",
    },
    {
      value: "medium",
      label: "Medium",
    },
    {
      value: "slow",
      label: "Slow",
    },
    
  ];

  useEffect(() => {

    if(!inputFileOut) return;
    console.log("inputFileOut", inputFileOut)
    // array of unique paired files out, so ex. [{file_name: "SRR13329771"}, {file_name: "SRR13329772"}, {file_name: "SRR13329773"}]
    let sample_list = [];
    inputFileOut && inputFileOut.forEach(file => {
        if(file.paired && file.read_num !== "2"){
          sample_list.push({
            file_name: file.paired,
            _id: file._id
          })
        }
    });

    console.log("sample_list", sample_list)
    setSampleInputFiles(sample_list)
  },[inputFileOut])

  return (
    <>
      <div className="text-block-50">
        Please review the selected analysis strategy and associated components.
        Select the desired computational speed and additional computing
        settings. The results from this analysis run are available within the
        Output tab.
      </div>
      <div className="div-block-95">
        {console.log(
          workflow,
          comparisonSelectionOut,
          referenceFileOut,
          inputFileOut
        )}
        {/* {workflow && workflow.steps?.map((step, i) => {
          return (
            <div className="div-block-96">
              <div className="text-block-52">{step}</div>
              <div className="div-block-97">
                <div className="text-block-51">Analysis Strategy </div>
                <div>Analysis Strategy </div>
              </div>
            </div>
          );
        })} */}
        <div className="div-block-96">
          <div className="text-block-52">Analysis Strategy</div>
          <div className="div-block-97">
            <div className="text-block-51">{workflow?.name}</div>
          </div>
        </div>
        <div className="div-block-96"  style={{
          display: inputFileOut?.length > 0 ? "flex" : "none",
          maxHeight: "200px",
          overflow: "auto"
        }}>
          <div className="text-block-52">
            Input Files
          </div>
          <div className="div-block-97" style={{
            alignItems: "inherit"
          }} >
            {sampleInputFiles &&
              sampleInputFiles?.map((in_f) => {
                return <div className="text-block-51">{in_f?.file_name}</div>;
              })}
          </div>
        </div>
        <div className="div-block-96" style={{
          display: comparisonSelectionOut?.length > 0 ? "flex" : "none"
        }}>
          <div className="text-block-52">Comparison Selection</div>
          <div className="div-block-97">
            <div className="text-block-51">{comparisonSelectionOut}</div>
          </div>
        </div>

        <div className="div-block-96">
          <div className="text-block-52">Reference File</div>
          <div className="div-block-97">
            <div className="text-block-51">
              {referenceFileOut?.fasta?.value.split("/").pop()}
              <br />
              {referenceFileOut?.gtf?.value.split("/").pop()}
              <br />
              {referenceFileOut?.stardb?.value.split("/").pop()}
            </div>
          </div>
        </div>

        <div className="div-block-96">
          <div className="text-block-52">Speed</div>
          <div className="div-block-97">
            <Select
              defaultValue={vmSpeed}
              // value={filterState.column}
              onChange={(x) => setVmSpeed(x)}
              options={vmSpeedOptions}
              // getOptionValue={(gene) => gene[1]}
              // getOptionLabel={(gene) => (
              //   <div
              //     style={
              //       {
              //         // fontSize: 21,
              //       }
              //     }
              //   >
              //     {gene}
              //   </div>
              // )}
              // className={"nav-dropdown"}
              // classNamePrefix={'dropdown-item"'}
              placeholder="VM Speed"
              menuPosition="fixed"
              menuPlacement="auto" 
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
