import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button, IconButton } from "@mui/material";

const ScatterTable = ({ transformedData, setFilteredItems, allData }) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const columns = useMemo(() => [
    {
      header: "Gene ID",
      accessorKey: "column1",
      sortable: true,
    },
    {
      header: "Gene Name",
      accessorKey: "column2",
      sortable: true,
    },
    {
      header: "Gene Type",
      accessorKey: "column3",
      sortable: true,
    },
    {
      header: "Log2FC",
      accessorKey: "column4",
      sortable: true,
      filterVariant: "range",
      filterFn: "between",
    },
    {
      header: "Padj",
      accessorKey: "column5",
      sortable: true,
      filterVariant: "range",
      filterFn: "between",
    },
  ]);

  const updateChart = () => {
    let new_filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original);

    var newFilteredItems = new_filteredData?.map((row) => [
      row.column1,
      row.column2,
      row.column3,
      row.column4,
      row.column5,
      // Add more columns if needed
    ]);

    setFilteredItems(newFilteredItems);
  };

  const table = useMaterialReactTable({
    data: transformedData ? transformedData : [],
    columns: columns,
    density: "compact",
    // enableColumnOrdering: true,
    // columnFilterDisplayMode: 'popover',
    enableColumnFilterModes: true,
    initialState: {
      // showColumnFilters: true,
      pagination: { pageSize: 15, pageIndex: 0 },
      density: "compact",
    },
    onColumnFiltersChange: setColumnFilters, //hoist internal columnFilters state to your state
    state: { columnFilters },
    onGlobalFilterChange: setGlobalFilter,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
        <Button
          style={{ backgroundColor: "grey", color: "white" }}
          onClick={() => {
            setFilteredItems(allData.slice(0, 5000));
            // reset table filters
            table.resetColumnFilters();
          }}
          variant="contained"
        >
          Reset
        </Button>
        <Button
          style={{ backgroundColor: "grey", color: "white" }}
          onClick={() => updateChart()}
          variant="contained"
        >
          Update Chart
        </Button>
      </Box>
    ),
  });

  return (
    <>
      <MaterialReactTable
        table={table}
        initialState={{
          density: "compact",
        }}
      />
    </>
  );
};

export default ScatterTable;
